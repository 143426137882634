import React from "react";
import { useMutation, useQuery } from "react-apollo";

import { Flex, Box, Header, Text, Label, NewButton, Stack } from "Atoms";
import { onlineOrderStatus, modalNames } from "Constants";
import { EatingOption, EatingPreference, WebOrderType } from "Types";
import { useLanguage, useModal } from "Providers";
import { SwishOrderResponse } from "../../OrderStatusContainer";
import { RESEND_ORDER_CONFIRMATION_EMAIL } from "GraphQLMutations";
import { GET_SHOP_SEND_SMS_NOTICE_FOR_PICKUP } from "GraphQLQueries";
import { formatSwedishPhoneNumber, checkPhoneNumberExists } from "Utils";

type Props = {
    order: SwishOrderResponse;
    statusColor: string;
};

const failedStatuses = [
    onlineOrderStatus.CANCELLED,
    onlineOrderStatus.CANCELLED_PAYMENT,
    onlineOrderStatus.FAILED_PAYMENT
];

export const OrderHeader: React.FC<Props> = ({ order, statusColor }) => {
    const { translate } = useLanguage();
    const { openModal } = useModal();
    const [resendOrderConfirmationEmail] = useMutation(RESEND_ORDER_CONFIRMATION_EMAIL);

    const { data } = useQuery(GET_SHOP_SEND_SMS_NOTICE_FOR_PICKUP, {
        variables: {
            shopId: order.shopId
        },
        fetchPolicy: "network-only",
        skip: !order.userInformation.onlineContactInformation.phoneNumber
    });

    const onOpenResendConfirmationEmailModal = (order: SwishOrderResponse) => {
        openModal(modalNames.RESEND_CONFIRMATION_EMAIL_MODAL, {
            mutation: resendOrderConfirmationEmail,
            orderId: order.id,
            orderEmail: order.userInformation.onlineContactInformation.email,
            openFrom: "expressOnline"
        });
    };

    const isExpress = order.webOrderType === WebOrderType.EXPRESS;
    const isHomeDelivery = order.eatingOption === EatingOption.HOME_DELIVERY;
    const deliveryType = isHomeDelivery
        ? translate("whenYouReceive")
        : order.tableMeta
            ? translate("whenYouAreServed")
            : translate("whenYouPickup");

    const hasPhoneNumberToDisplay = checkPhoneNumberExists(order.userInformation.onlineContactInformation.phoneNumber);

    return (
        <>
            <Flex direction={["column", "row"]} justify="space-between" align={["normal", "center"]}>
                <Box mb={[8, 0]}>
                    <Flex flexDirection="column" alignItems={["center", "flex-start"]}>
                        <Header as="h1" size="2xl" mb={1}>
                            {translate("hello")} {order.userInformation.onlineContactInformation.name}!
                        </Header>
                        <Text fontSize="xl" textAlign={["center", "left"]} color="gray.600" mb={5} mt="0">
                            {translate("yourOrderFrom")} {order.shopName}.
                        </Text>
                        {order.onlineOrderStatus !== onlineOrderStatus.WAITING &&
                            !failedStatuses.includes(order.onlineOrderStatus) && (
                                <Stack stretch={2}>
                                    {!isExpress && (
                                        <Text fontSize="xl" textAlign={["center", "left"]} color="gray.600">
                                            {translate("mailHasBeenSentTo")}:{" "}
                                            <Text as="span" color="blue.600">
                                                {order.userInformation.onlineContactInformation.email}.
                                            </Text>
                                        </Text>
                                    )}
                                    {(data?.getShopOnlineSettings?.sendSmsNoticeForPickup || isExpress) &&
                                        !!hasPhoneNumberToDisplay && (
                                            <Text fontSize="xl" textAlign={["center", "left"]} color="gray.600">
                                                {translate("receiveSmsForReadyOne")}:{" "}
                                                <Text as="span" color="blue.600">
                                                    {formatSwedishPhoneNumber(
                                                        order.userInformation.onlineContactInformation.phoneNumber
                                                    )}{" "}
                                                </Text>
                                                {translate("receiveSmsWithNumberForReadyTwo")}.
                                            </Text>
                                        )}
                                    {order.orderNo > 0 && (
                                        <Header
                                            as="h4"
                                            size="md"
                                            textAlign={["center", "left"]}
                                            fontWeight="normal"
                                            color="gray.600"
                                            margin="0"
                                        >
                                            {translate("note")}! {translate("dontForgetToShowOrderNo")} {deliveryType}{" "}
                                            {translate("yourFood")}.
                                        </Header>
                                    )}
                                </Stack>
                            )}
                    </Flex>
                </Box>
                {order.orderNo > 0 && (
                    <Flex direction="column">
                        <Box minWidth={48}>
                            <Label
                                backgroundColor={statusColor}
                                height="5rem"
                                width="100%"
                                justifyContent="center"
                                fontSize="2xl"
                                color="white"
                            >
                                {translate("orderNo")}: {order.orderNo}
                            </Label>
                        </Box>
                        {isExpress && (
                            <Box mt={4}>
                                <NewButton fullWidth onClick={() => onOpenResendConfirmationEmailModal(order)}>
                                    {translate("sendReceiptToEmail")}
                                </NewButton>
                            </Box>
                        )}
                    </Flex>
                )}
            </Flex>
        </>
    );
};
