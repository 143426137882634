import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { useSpring, animated } from "react-spring";

import { formatShopUrlName } from "Utils";
import { Box, Flex, Header, Icon, NewButton, Text } from "Atoms";
import { useOnline, useLanguage, Languages } from "Providers";
import { LanguageText, LanguageHeader } from "Components";
import { ExpressOnlineCheckoutContainer, ExpressOnlineWrapper } from "../../checkout/components/ExpressCheckoutForm";
import "../expressOrder.scss";
import { envConstants, frontyEnv } from "Constants";
import { usePosStore, useQoplaStore, useOrderWindowsStore } from "Stores";
import { formatSwedishPhoneNumber, checkPhoneNumberExists } from "Utils";
import { OnlineOrderStatus, Order } from "Types";

type Props = {
    order: Order;
    subdomain: string;
};

export const ExpressOrder: React.FC<Props> = ({ order, subdomain }) => {
    const [shouldRunAnimation, setShouldRunAnimation] = useState(false);

    const { selectedShop } = useQoplaStore();
    const { selectedPos } = usePosStore();

    const navigate = useNavigate();
    const { resetFoodOptions } = useOnline();
    const { emptyCart, activeOrderWindowId } = useOrderWindowsStore();
    const { translate, userLanguage, onUserLanguageChange } = useLanguage();

    const redirectTimer = useRef<number>();
    const groupUrl = envConstants.PROFILE === frontyEnv.DEV ? "dev.qopla.com" : "qopla.com";

    const posAppender = selectedPos?.publicId ? `${selectedPos?.publicId}/` : "";
    const restaurantUrl = `/express/restaurant/${formatShopUrlName(order.shopName)}/${selectedShop?.publicId
        }/${posAppender}order`;
    const expressOnlineCompanyPageUrl = `https://${subdomain}.${groupUrl}`;
    const cancelledBySwishApp = order.failedReason === "User cancelled payment in SWISH app";

    //@ts-ignore
    const { transform } = useSpring({
        from: { transform: "translate3d(0, 0%, 0)" },
        to: {
            transform: `translate3d(0, ${shouldRunAnimation ? "100%" : "0%"}, 0)`
        },
        config: {
            mass: 2
        },
        onRest: () => {
            if (shouldRunAnimation) {
                !!subdomain ? window.location.assign(expressOnlineCompanyPageUrl) : navigate(restaurantUrl);
            }
        }
    });

    const timeUntilRedirect = 10000;

    useEffect(() => {
        if (!cancelledBySwishApp) {
            redirectTimer.current = window.setTimeout(() => {
                emptyCart(activeOrderWindowId);
                resetFoodOptions();

                if (userLanguage === Languages.EN) {
                    onUserLanguageChange(Languages.SV);
                }
                setShouldRunAnimation(true);
            }, timeUntilRedirect);

            return () => window.clearTimeout(redirectTimer.current);
        }
    }, []);

    const expressOnlineOrderConfirmation = selectedShop?.settings.onlineSettings.expressOnlineOrderConfirmation;
    const hasPhoneNumber = checkPhoneNumberExists(order.userInformation.onlineContactInformation.phoneNumber);
    const phoneNumberText = hasPhoneNumber
        ? formatSwedishPhoneNumber(order.userInformation.onlineContactInformation.phoneNumber)
        : translate("yourSwishNumber");

    const failedOrder =
        order.onlineOrderStatus === OnlineOrderStatus.DECLINED ||
        order.onlineOrderStatus === OnlineOrderStatus.CANCELLED_PAYMENT;

    //TO-DO Use properly translated SWISH_ERROR_MESSAGES
    const errorText = order.failedReason;

    return (
        <animated.div
            style={{
                height: "100%",
                transform: transform.interpolate((transform: any) => transform)
            }}
        >
            <ExpressOnlineCheckoutContainer overflow="hidden">
                <ExpressOnlineWrapper>
                    <Flex flexDirection="column" width="100%" alignItems="center" p={4} justifyContent="center">
                        <Flex width="auto" height="auto" flexDirection="column" minW="35rem">
                            <Flex
                                direction="column"
                                bg="white"
                                p={4}
                                boxShadow="0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08)"
                                rounded="md"
                                width="100%"
                                height="auto"
                                alignItems="center"
                                textAlign="center"
                                mb={4}
                                mr={{
                                    base: 0,
                                    lg: 2
                                }}
                            >
                                {cancelledBySwishApp || failedOrder ? (
                                    <Box>
                                        <LanguageHeader
                                            tid={cancelledBySwishApp ? "youHaveCancelledSwishPayment" : "failedPayment"}
                                            as="h1"
                                            size="xl"
                                            mb={2}
                                            color="gray.900"
                                            alignSelf="center"
                                        />
                                        {cancelledBySwishApp && (
                                            <LanguageHeader
                                                tid="tryAgain"
                                                as="h1"
                                                size="xl"
                                                m={0}
                                                color="gray.900"
                                                alignSelf="center"
                                            />
                                        )}
                                        {failedOrder && (
                                            <Text fontSize={"1.2rem"} color="gray.600">
                                                {translate("errorMessage")}:
                                                <Box as="span" ml={2}>
                                                    {errorText}
                                                </Box>
                                            </Text>
                                        )}
                                    </Box>
                                ) : (
                                    <>
                                        <Flex height="auto" alignSelf="center" justifyContent="center">
                                            <LanguageHeader
                                                tid={expressOnlineOrderConfirmation ? "pickUpTimeSent" : "orderNumber"}
                                                as="h3"
                                                fontSize="6xl"
                                                m={0}
                                                color="gray.900"
                                            />
                                        </Flex>
                                        <Flex justifyContent="center">
                                            {!expressOnlineOrderConfirmation ? (
                                                <Header
                                                    as="h1"
                                                    fontSize="16rem"
                                                    m={0}
                                                    color="gray.900"
                                                    alignSelf="center"
                                                >
                                                    {order.orderNo}
                                                </Header>
                                            ) : (
                                                <Icon name="FaSms" height="14.6em" width="14.5em" />
                                            )}
                                        </Flex>
                                        {expressOnlineOrderConfirmation && hasPhoneNumber && (
                                            <Flex height="auto" alignSelf="center" justifyContent="center">
                                                <Header
                                                    as="h3"
                                                    fontSize="6xl"
                                                    m={0}
                                                    color="gray.900"
                                                    alignSelf="center"
                                                >
                                                    {`${translate("to")} ${formatSwedishPhoneNumber(
                                                        order.userInformation.onlineContactInformation.phoneNumber
                                                    )}`}
                                                </Header>
                                            </Flex>
                                        )}
                                    </>
                                )}
                            </Flex>
                            {!cancelledBySwishApp && !failedOrder && (
                                <Flex
                                    direction="column"
                                    bg="white"
                                    p={4}
                                    boxShadow="0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08)"
                                    rounded="md"
                                    width="100%"
                                    height="auto"
                                    alignItems="center"
                                    textAlign="center"
                                    mb={4}
                                    mr={{
                                        base: 0,
                                        lg: 2
                                    }}
                                >
                                    {!expressOnlineOrderConfirmation && (
                                        <Flex height="auto" alignSelf="center">
                                            <Text fontSize="5xl" m={0} color="gray.900">
                                                {hasPhoneNumber && (
                                                    <>
                                                        <Text as="span">{translate("receiveSmsForReadyOne")}</Text>
                                                        <br />
                                                        <Text as="span" fontWeight="bold">
                                                            {phoneNumberText}
                                                        </Text>
                                                        <br />
                                                        <Text as="span">
                                                            {translate("receiveSmsWithNumberForReadyTwo")}
                                                        </Text>
                                                    </>
                                                )}
                                            </Text>
                                        </Flex>
                                    )}
                                    <Flex
                                        direction="column"
                                        bg="white"
                                        p={4}
                                        width="100%"
                                        height="auto"
                                        alignItems="center"
                                        textAlign="center"
                                        mt={4}
                                    >
                                        <Flex
                                            width="100%"
                                            height={3}
                                            backgroundColor="primary"
                                            rounded="md"
                                            className="order-status-progress-bar"
                                        />
                                    </Flex>
                                </Flex>
                            )}
                            <NewButton
                                onClick={() => {
                                    if (cancelledBySwishApp || failedOrder) {
                                        navigate(-1);
                                    } else {
                                        emptyCart(activeOrderWindowId);
                                        resetFoodOptions();
                                        !!subdomain
                                            ? window.location.assign(expressOnlineCompanyPageUrl)
                                            : navigate(restaurantUrl);
                                    }
                                }}
                                themeColor="green"
                                type="submit"
                                fullWidth
                                size="xl"
                                rounded="md"
                            >
                                {cancelledBySwishApp || failedOrder ? (
                                    <LanguageText tid="tryAgain" m={0} fontSize="3xl" />
                                ) : (
                                    <Flex height="auto" width="100%" justifyContent="center" alignItems="center">
                                        <LanguageText tid="nextCustomer" m={0} mr={4} fontSize="3xl" />
                                        <Box as={FaUser} fontSize="3xl" />
                                    </Flex>
                                )}
                            </NewButton>
                        </Flex>
                    </Flex>
                </ExpressOnlineWrapper>
            </ExpressOnlineCheckoutContainer>
        </animated.div>
    );
};
