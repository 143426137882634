import React from "react";

import { Text, NewList, NewListItem } from "Atoms";
import { Modifications, Modification } from "Types";
import { Languagekey, useLanguage } from "LanguageProvider";

type Props = {
    modifications: Modifications | undefined;
    productPrice: number;
};

export const CustomerModificationsList: React.FC<Props> = ({ modifications, productPrice }) => {
    const { translate } = useLanguage();
    if (!modifications) return null;

    const modificationsWithHeader = Object.entries(modifications).reduce(
        (acc: Array<{ val: Modification | null; header: string }>, [key, val]) => {
            acc.push({
                header: translate(key as Languagekey),
                val: val && Array.isArray(val) && val.length > 0 ? (val as Modification[])[0] : null
            });
            return acc;
        },
        []
    );

    return (
        <NewList listStyle="none" pl={4}>
            {modificationsWithHeader.map(
                modification =>
                    modification.val &&
                    modification.val.name &&
                    !!modification.val.name.trim() && (
                        <NewListItem key={modification.header} display="flex" justifyContent="space-between">
                            <Text as="span" color="gray.600">
                                {modification.header}: {modification.val.name}
                            </Text>
                            {modification.val.price > 0 && modification.val.price !== productPrice && (
                                <Text as="span" color="gray.600">
                                    {modification.val.price} kr
                                </Text>
                            )}
                        </NewListItem>
                    )
            )}
        </NewList>
    );
};
