import React from "react";
import { RiInformationLine } from "@react-icons/all-files/ri/RiInformationLine";

import { Flex, Box, Header, Text, NewList, NewListItem, Stack, ToolTip, FlexProps } from "Atoms";
import { formatSwedishFinancialNumbers } from "TempUtils";
import { applyServiceFeeVatToVatRatesAndAmounts, formatDateToLocal, getVatAmount } from "Utils";
import { OnlineAddonsList, OnlineBundleItemsList } from "Organisms";
import { LanguageHeader } from "Components";
import { useLanguage } from "Providers";
import { CustomerModificationsList } from "../CustomerModificationsList";
import { SwishOrderResponse } from "../../OrderStatusContainer";
import { DiscountMeta, EatingOption, EatingPreference, ServiceFee, SubscriptionMeta } from "Types";
import { roundNumber } from "NumberUtils";
import { GLOBAL_TIP_PRODUCT_NAME } from "Constants";
import { useQoplaStore } from "Stores";

type Props = {
    order: SwishOrderResponse;
};

const mergeDiscountsAndSubscriptions = (discountMeta: DiscountMeta, subscriptionMeta: SubscriptionMeta) => {
    if (discountMeta?.discounts) {
        return discountMeta.discounts?.reduce<{ name: string; value: number; variant: "subscription" | "discount" }[]>(
            (acc, next) => {
                const foundSubscriptionDiscount = next.discountId === subscriptionMeta?.subscriptionId;

                acc.push({
                    name: next.name,
                    value: next.discountValue,
                    variant: foundSubscriptionDiscount ? "subscription" : "discount"
                });

                return acc;
            },
            []
        );
    }

    return [];
};

export const OrderSummary: React.FC<Props> = ({ order }) => {
    const { translate } = useLanguage();
    const { selectedShop } = useQoplaStore();
    const purchaseDate = formatDateToLocal(order.purchaseDate, `YYYY-MM-DD [${translate("theClock")}] HH:mm`);
    const combinedDiscounts = order.discountMeta?.discounts && order.discountMeta.discounts?.length > 1;
    const discountsAndSubscriptions = mergeDiscountsAndSubscriptions(order.discountMeta, order.subscriptionMeta);
    const hasGiftCard = order.giftCardMeta?.amount > 0 ?? false;
    const giftCardAmount = order.giftCardMeta?.amount ?? 0;
    const addedServiceFee = order?.serviceFee as ServiceFee | undefined;
    const addedServiceFeeAmount = addedServiceFee?.amount || 0;

    const discountTextSummary = () => {
        if (!combinedDiscounts) {
            if (order.discountMeta.rate && order.discountMeta.amount) {
                if (order.discountMeta.rate > 1) {
                    return `${roundNumber(order.discountMeta.rate)} %`;
                }
                return `${roundNumber(order.discountMeta.amount)} kr`;
            }
            return order.discountMeta.rate
                ? `${roundNumber(order.discountMeta.rate * 100)} %`
                : `${roundNumber(order.discountMeta.amount!)} kr`;
        }
        return "";
    };

    const _orderProducts = order.orderProducts.filter(orderProduct => orderProduct.name !== GLOBAL_TIP_PRODUCT_NAME);

    const vatRatesAndAmounts = applyServiceFeeVatToVatRatesAndAmounts(addedServiceFee, order.vatRatesAndAmounts);
    const isHomeDelivery = order.eatingOption === EatingOption.HOME_DELIVERY;

    return (
        <Flex
            as="section"
            direction="column"
            bg="white"
            p={8}
            boxShadow="0 0 20px rgba(0, 0, 0, 0.05), 0 0px 40px rgba(0, 0, 0, 0.08)"
            rounded="md"
        >
            <Box as="header">
                <LanguageHeader tid="summary" as="h3" size="md" textAlign="center" />
                {order.tableMeta?.name && (
                    <Text textAlign="center" color="gray.700">
                        {translate("orderedTo")}: <b>{order.tableMeta.name}</b>
                    </Text>
                )}
                <Text textAlign="center" color="gray.700">
                    {translate("orderDate")}: {purchaseDate}
                </Text>
            </Box>
            <Box flex="1" my={8}>
                <NewList listStyle="none" height="100%" stretch={4}>
                    {_orderProducts.map(
                        (
                            {
                                name,
                                totalPrice,
                                modifications,
                                selectedBundleProductItems,
                                addons,
                                quantity,
                                comment,
                                discountValue
                            },
                            index: number
                        ) => {
                            const discountsOrSubscriptionsForProduct = discountValue
                                ? discountsAndSubscriptions.filter(
                                    discountsOrSubscription => discountsOrSubscription.value === discountValue
                                )
                                : [];
                            const isFree = totalPrice === 0;
                            return (
                                <NewListItem key={index}>
                                    <Flex direction="column">
                                        <Flex justify="space-between">
                                            <Header as="h4" fontSize="lg" color="gray.900">
                                                {quantity}st {name}
                                            </Header>
                                            <Text color={isFree ? "green.500" : "black"}>
                                                {totalPrice !== 0
                                                    ? formatSwedishFinancialNumbers(totalPrice)
                                                    : translate("free")}
                                            </Text>
                                        </Flex>
                                        <Stack stretch={4}>
                                            {discountsOrSubscriptionsForProduct.map(({ name, variant, value }) => (
                                                <Flex
                                                    key={name}
                                                    color="red.500"
                                                    fontWeight="600"
                                                    justifyContent="space-between"
                                                >
                                                    <Text pl="1em" textTransform="capitalize">
                                                        - {translate(variant)}
                                                    </Text>
                                                    <Text>{formatSwedishFinancialNumbers(value)}</Text>
                                                </Flex>
                                            ))}
                                        </Stack>
                                        <OnlineAddonsList mb={2} addons={addons} />
                                        {!!modifications && (
                                            <CustomerModificationsList
                                                modifications={modifications}
                                                productPrice={totalPrice}
                                            />
                                        )}
                                        <OnlineBundleItemsList selectedBundleItems={selectedBundleProductItems} />
                                        {comment && (
                                            <>
                                                <Text margin={0} fontWeight="bold">
                                                    {translate("comment")}:
                                                </Text>
                                                <Box
                                                    backgroundColor="gray.100"
                                                    rounded={3}
                                                    padding={4}
                                                    marginY={3}
                                                    wordBreak="break-word"
                                                >
                                                    <Text>{comment}</Text>
                                                </Box>
                                            </>
                                        )}
                                    </Flex>
                                </NewListItem>
                            );
                        }
                    )}
                </NewList>
            </Box>
            <Flex as="footer" align="flex-end" direction="column" height="auto">
                <Stack stretch={4} align="flex-end" w="full" bg="gray.100" rounded="lg" p={4} mt={8}>
                    {!!order.discountMeta && (
                        <Flex w="100%" justify="space-between" fontSize="lg">
                            <Text as="span" color="gray.700">
                                {translate("originalPrice")}
                            </Text>
                            <Text as="span">
                                {formatSwedishFinancialNumbers(
                                    order.discountMeta
                                        ? order.discountMeta.originalPrice
                                        : giftCardAmount > 0
                                            ? order.totalAmount
                                            : 0
                                )}
                            </Text>
                        </Flex>
                    )}
                    {!!order.tip && (
                        <Flex w="100%" justify="space-between" fontSize="lg">
                            <Text as="span" color="gray.700">
                                {translate("tip")}
                            </Text>
                            <Text as="span">{formatSwedishFinancialNumbers(order.tip)}</Text>
                        </Flex>
                    )}

                    {isHomeDelivery && (
                        <Flex w="100%" justify="space-between" fontSize="lg">
                            <Text as="span" color="gray.700">
                                {translate("deliveryFee")}
                            </Text>
                            <Text as="span">
                                {order.deliveryFee === 0
                                    ? translate("free")
                                    : `${formatSwedishFinancialNumbers(order.deliveryFee)}`}
                            </Text>
                        </Flex>
                    )}
                    {discountsAndSubscriptions.map(({ name, variant, value }) => (
                        <Flex key={name} w="100%" color="orange.500" justify="space-between" fontSize="lg">
                            <Text as="span">{`${translate(variant)} (${name} ${discountTextSummary()})`}</Text>
                            <Text as="span">{formatSwedishFinancialNumbers(value)}</Text>
                        </Flex>
                    ))}
                    {hasGiftCard && (
                        <Flex w="100%" justify="space-between" fontSize="lg" color="green.500">
                            <Text as="span">{translate("giftCard")}</Text>
                            <Text as="span">{formatSwedishFinancialNumbers(giftCardAmount)}</Text>
                        </Flex>
                    )}
                    {addedServiceFeeAmount !== 0 && (
                        <Flex justify="space-between" w="100%" fontSize="lg">
                            <ToolTip placement={"bottom"} text={translate("serviceFeeExplanation")}>
                                <Stack stretch={2} isInline>
                                    <Text m={0}>{translate("serviceFee")}</Text>
                                    <Box as={RiInformationLine} color="gray.500" rounded="full" height="100%" />
                                </Stack>
                            </ToolTip>

                            <Text m="0" fontSize="lg">
                                {formatSwedishFinancialNumbers(addedServiceFeeAmount)}
                            </Text>
                        </Flex>
                    )}
                    <Flex w="100%" justify="space-between" mt={4}>
                        <Text as="span" fontSize="xl" lineHeight="1.2" fontWeight="bold">
                            Total
                        </Text>
                        <Text as="span" fontSize="xl" lineHeight="1.2" fontWeight="bold">
                            {formatSwedishFinancialNumbers(order.totalAmount + addedServiceFeeAmount - giftCardAmount)}
                        </Text>
                    </Flex>
                    {vatRatesAndAmounts.map(
                        ({ vatRate, amount }) =>
                            amount !== 0 && (
                                <Flex key={amount} color="gray.600" w="100%" justify="space-between" fontSize="lg">
                                    <Text as="span">
                                        {translate("vat")} {vatRate}%
                                    </Text>
                                    <Text as="span">{formatSwedishFinancialNumbers(amount)}</Text>
                                </Flex>
                            )
                    )}
                </Stack>
            </Flex>
        </Flex>
    );
};
